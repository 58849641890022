import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Input, Modal, notification, Select, Table, Tag } from 'antd';
import { creditCardStatusUpdate, getAllCreditCard, getCreditCardStatus } from '../api/creditCardApi';
import dayjs from 'dayjs';
import { BsThreeDots } from 'react-icons/bs';
import { RxCross2 } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';

const AllCreditCard = () => {
  const navigate=useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [isCreditCardStatus, setIsCreditCardStatus] = useState(false);
  const [allLoanStatus, setAllLoanStatus] = useState();
  const [fileNo, setFileNo] = useState(null);
  const [creditCardStatus, setCreditCardStatus] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);

  // Fetch All Credit Cards

  const fetchAllCreditCard = async (params = {}) => {
    setLoading(true);
    try {
      const {status, data} = await getAllCreditCard(params);
      if (status === 200) {
    
        setData(data?.data?.data);
        setPagination((prev) => ({
          ...prev,
          total:data?.data.total,
        }));
      }
    } catch (error) {
      console.error('Error fetching credit cards:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
   

    fetchAllCreditCard();
  }, []); // Isolate dependencies

  // Handle Table Change
  const handleTableChange = (newPagination) => {
    setPagination((prev) => ({
      ...prev,
      current: newPagination.current,
      pageSize: newPagination.pageSize,
    }));
  };





  const handleCreditCardStatusUpdate = async () => {
    try {
      setBtnLoading(true);

      const { status } = await creditCardStatusUpdate(fileNo, {status:creditCardStatus});

      if (status === 200) {
        // Success notification
        notification.success({
          message: "Success",
          description: "Credit Card status updated successfully!",
        });

        setBtnLoading(false);
        setIsCreditCardStatus(false);
        setCreditCardStatus(null);
        setFileNo(null);
        fetchAllCreditCard();
      }
    } catch (error) {
      setBtnLoading(false);

      // Error notification
      notification.error({
        message: "Error",
        description:
          error.response?.data?.message ||
          "Failed to credit card  status. Please try again.",
      });

      console.error(error);
    }
  };


  // Fetch loan Status
  useEffect(() => {
    const fetchCreditCardStatus = async () => {
      try {
        const { data, status } = await getCreditCardStatus();
        if (status === 200) {
          setAllLoanStatus(data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCreditCardStatus();
  }, []);

     //loan status modal open
     const creditCardStatusModalOpen = () => {
      setIsCreditCardStatus(true);
    };
  
    
    //loan status modal close
    const creditCardStatusModalClose = () => {
      setIsCreditCardStatus(false);
      setFileNo(null);
      setCreditCardStatus(null);
    };

  // Table columns
  const columns = [

    {
      title: 'File No',
      dataIndex: 'file_no',
      key: 'file_no',
      
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record)=>{
        return(
          <span>{`${record.first_name} ${record.last_name}`}</span>
        )
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Date of Birth',
      dataIndex: 'date_of_birth',
      key: 'date_of_birth',
    },
    {
      title: 'Created At',
      dataIndex: 'created_a',
      key: 'created_a',
      render: (text)=>{
        return(
          <span>{dayjs(text).format("YYYY-MM-DD")}</span>
        )
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "Fresh Lead", value: "fresh_lead" },
        { text: "Upload Documents", value: "upload_documents" },
        { text: "Banking Pendency", value: "banking_pendency" },
        { text: "Assign", value: "assign" },
        { text: "Reject", value: "reject" },
        { text: "Login", value: "login" },
        { text: "Hold", value: "hold" },
      ],
      onFilter: (value, record) => record.status.includes(value),
      render: (text) => {
        const statusMapping = {
          fresh_lead: { label: "Fresh Lead", color: "#004085" },
          upload_documents: { label: "Upload Documents", color: "#085858" },
          banking_pendency: { label: "Banking Pendency", color: "#995700" },
          assign: { label: "Assign", color: "#005a00" },
          reject: { label: "Reject", color: "#8b0000" },
          login: { label: "Login", color: "#7b6400" },
          hold: { label: "Hold", color: "#4b0082" },
        };
        const status = statusMapping[text] || { label: text, color: "#595959" };

        return (
          <Tag
            style={{
              backgroundColor: status.color,
              color: "#fff",
              border: "none",
            }}
          >
            {status.label}
          </Tag>
        );
      },
    },

    {
      title: "Action",
      key: "Action",
      width: 150,
      render: (text, record) => {
        const items = [
          {
            key: "documentsView",
            label: (
              <span
                onClick={() => {
                  navigate(`/credit-card/card-info/${record.file_no}`, {
                    state: { cardInfo: record },
                  });
                }}
              >
                Documents View
              </span>
            ),
          },
          {
            key: "changeLoanStatus",
            label: (
              <span
                onClick={() => {
                  setFileNo(record.file_no);
                  creditCardStatusModalOpen();
                }}
              >
                Change Loan Status
              </span>
            ),
          },

          
        ];
        return (
          <Dropdown menu={{ items }} trigger={["click"]}>
            <BsThreeDots className="text-xl text-zinc-600 cursor-pointer" />
          </Dropdown>
        );
      },
    },
    {
      title: "Partner Details",
      children: [
        {
          title: "ID",
          dataIndex: ["partner", "uuid"], // Access nested field
          key: "partnerUuid",
        },
        {
          title: "Name",
          dataIndex: ["partner", "name"], // Access nested field
          key: "partnerName",
        },
        {
          title: "Email",
          dataIndex: ["partner", "email"], // Access nested field
          key: "partnerEmail",
        },
        {
          title: "Mobile Number",
          dataIndex: ["partner", "mobile_number"], // Access nested field
          key: "partnerMobile",
        },
      
      ],
    },
  ];





  return (
    <div className='p-6'>
      <h1 className='text-zinc-700 font-semibold text-2xl'>All Credit Card</h1>

      <div className='bg-white rounded-lg shadow-sm p-4 mt-4'>

      <div className="flex justify-end mb-4">
          <Input
            // placeholder="Search by File No"
            // value={searchText}
            // onChange={(e) => setSearchText(e.target.value)}
            // onPressEnter={handleSearch}
            style={{ width: 250 }}
          />
        </div>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        onChange={handleTableChange}
        bordered
        scroll={{ x: "max-content" }}
      /> 
      </div>

      <Modal
        open={isCreditCardStatus}
        onCancel={creditCardStatusModalClose}
        title={null}
        width={400}
        centered
        footer={null}
        closable={false}
        maskClosable={false}
        modalRender={(modal) => {
          return React.cloneElement(modal, {
            style: {
              ...modal.props.style,
              ...{ borderRadius: 10, padding: 0 },
            },
          });
        }}
      >
        <div className="flex justify-between items-center py-2 px-4 border-b-[1px] border-b-zinc-300">
          <h1 className="text-zinc-700 font-semibold text-xl">
            Change Loan Status
          </h1>
          <span
            onClick={creditCardStatusModalClose}
            className="text-zinc-600 hover:text-zinc-800 font-semibold text-2xl cursor-pointer"
          >
            <RxCross2/>
          </span>
        </div>

        <div className="p-6">


          <div className="mt-4 px-6 w-full py-4 flex flex-col gap-1">
            <label htmlFor="" className="text-zinc-700 font-semibold">
              Select Status
            </label>
            <Select
              size="large"
              style={{ width: "100%" }}
              value={creditCardStatus}
              onChange={(value) => setCreditCardStatus(value)}
              placeholder={"Select Account Status"}
            >
              {allLoanStatus?.map((status) => {
                return (
                  <Select.Option key={status.key} value={status.key}>
                    {status.name}
                  </Select.Option>
                );
              })}

            </Select>
          </div>

          <div className="mt-4 px-6 flex flex-col gap-4 pb-4">
            <Button
              loading={btnLoading}
              onClick={handleCreditCardStatusUpdate}
              disabled={creditCardStatus !== null ? false : true}
              className="w-full h-10 rounded-3xl bg-green-700 text-white"
            >
              Save
            </Button>
            <button
              onClick={creditCardStatusModalClose}
              className="w-full h-10
              rounded-3xl border-[1px]
              border-green-700
              text-green-700"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AllCreditCard;
