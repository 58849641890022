import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Card, Typography, Divider, Tooltip, Button, notification } from 'antd';
import { getGovermentLoanInfo } from '../api/govermnetLoanApi';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import pdfIcon from "../assets/pdfIcon.png"
const { Title, Text } = Typography;

const GovernmentLoanInfo = () => {
  const { fileNo } = useParams();
  const location = useLocation();
  const { loanInfo } = location.state || {};
  const [documents, setDocuments] = useState([]);

  // Fetch Government Loan Details
  useEffect(() => {
    const fetchLoanDetails = async () => {
      try {
        const { data, status } = await getGovermentLoanInfo(fileNo);
        if (status === 200) {
          setDocuments(data?.data);
        }
      } catch (error) {
        console.error('Error fetching loan details:', error);
      }
    };
    fetchLoanDetails();
  }, [fileNo]);

  if (!loanInfo) {
    return (
      <div className="text-center mt-10 text-red-500 text-lg">
        No loan information available.
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6">
      <Title level={2} className="mb-6">Government Loan Information</Title>

      {/* Loan Details Section */}
      <Card className="mb-6 shadow-sm">
        <Title level={4} className="mb-4 text-blue-600">Loan Details</Title>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <DetailItem label="File Number" value={loanInfo.file_no} />
          <DetailItem label="Loan Amount Requirement" value={loanInfo.loan_amount_requirement} />
          <DetailItem label="Type of Applicant" value={loanInfo.type_of_applicant} />
          <DetailItem label="Type of Activity" value={loanInfo.type_of_activity} />
          <DetailItem label="Applicant Name" value={loanInfo.applicant_name} />
          <DetailItem label="Email" value={loanInfo.applicant_email} />
          <DetailItem label="Date of Birth" value={new Date(loanInfo.applicant_dob).toLocaleDateString()} />
          <DetailItem label="Gender" value={loanInfo.gender} />
          <DetailItem label="PAN Number" value={loanInfo.applicant_pan_number} />
          <DetailItem label="Father's Name" value={loanInfo.father_name} />
          <DetailItem label="Mobile Number" value={loanInfo.mobile_number} />
          <DetailItem label="Alternate Number" value={loanInfo.alternate_number} />
          <DetailItem label="Marital Status" value={loanInfo.applicant_marital_status} />
          <DetailItem label="Number of Children" value={loanInfo.number_of_children} />
          <DetailItem label="Purpose of Loan" value={loanInfo.purpose_of_loan} />
          <DetailItem label="Type of Loan" value={loanInfo.type_of_loan} />
          <DetailItem label="Monthly Salary" value={loanInfo.monthly_salary} />
          <DetailItem label="Experience in Current Business" value={`${loanInfo.experience_in_current_business} years`} />
          <DetailItem label="Duration of Loan" value={loanInfo.duration_of_loan} />
          <DetailItem label="Profession" value={loanInfo.applicant_profession} />
          <DetailItem label="Residence Pincode" value={loanInfo.applicant_residence_pincode} />
          <DetailItem label="Bank Name" value={loanInfo.bank_name} />
          <DetailItem label="Account Number" value={loanInfo.account_number} />
          <DetailItem label="IFSC Code" value={loanInfo.ifsc_code} />
          <DetailItem label="Status" value={loanInfo.status} />
          <DetailItem label="Loan Type" value={loanInfo.loan} />
          <DetailItem label="Created At" value={new Date(loanInfo.created_at).toLocaleString()} />
        </div>
      </Card>

      {/* Document Details Section */}
      <Card className="shadow-sm">
        <Title level={4} className="mb-4 text-blue-600">Document Details</Title>

        {Object.keys(documents).length === 0 || !Object.values(documents).some(value => value && typeof value === 'string' && value.startsWith('http')) ? (
          <p className="text-center text-gray-600 font-semibold text-lg mt-6">
            <span className="inline-block bg-yellow-200 text-yellow-700 px-4 py-2 rounded-md shadow-md">
              No Documents Uploaded Yet
            </span>
          </p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {Object.entries(documents).map(([key, value]) => {
              if (value && typeof value === 'string' && value.startsWith('http')) {
                return (
                  <DocumentItem
                    key={key}
                    label={formatDocumentLabel(key)}
                    url={value}
                    password={documents[`${key}_password`] || 'N/A'}
                  />
                );
              }
              return null;
            })}
          </div>
        )}
      </Card>
    </div>
  );
};

// DetailItem Component
const DetailItem = ({ label, value }) => (
  <div className="flex flex-col">
    <Text className="font-semibold text-gray-700">{label}</Text>
    <Text className="text-gray-900">{value}</Text>
    <Divider className="mt-2 mb-0" />
  </div>
);

// Helper to format document labels
const formatDocumentLabel = (key) => {
  return key
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

const DocumentItem = ({ label, url, password }) => {
    const isImage = url.match(/\.(jpeg|jpg|gif|png)$/) != null;
    const isPDF = url.match(/\.pdf$/) != null;
  
    const handleDownload = (fileUrl) => {
      window.open(fileUrl, '_blank');
    };
  
    const handleCopyPassword = () => {
      navigator.clipboard.writeText(password).then(() => {
        notification.success({
          message: 'Password Copied!',
          description: 'The password has been successfully copied to your clipboard.',
          placement: 'topRight',
        });
      }).catch(() => {
        notification.error({
          message: 'Failed to Copy Password',
          description: 'An error occurred while copying the password.',
          placement: 'topRight',
        });
      });
    };
  
    return (
      <div className="flex flex-col p-4 border rounded-lg shadow-sm bg-white">
        <Text className="font-semibold text-gray-700 text-lg mb-2">{label}</Text>
  
        <div className="mt-4">
          {isImage ? (
            <div className="flex justify-center mb-4">
              <img
                src={url}
                alt={label}
                className="max-w-xs max-h-40 object-contain rounded-lg cursor-pointer"
                onClick={() => handleDownload(url)}
              />
            </div>
          ) : isPDF ? (
            <div className="flex justify-center mb-4 h-40">
              <img className="w-28 h-28 object-cover" src={pdfIcon} alt="PDF Icon" />
            </div>
          ) : null}
  
          <div className="flex items-center justify-between space-x-4 mt-4">
            <Button
              icon={<DownloadOutlined />}
              onClick={() => handleDownload(url)}
              className="w-full sm:w-auto bg-green-700 text-white"
            >
              Download
            </Button>
  
            <Tooltip title={`Password: ${password}`} placement="top">
              <Button
                icon={<EyeOutlined />}
                className="w-full sm:w-auto text-blue-700"
                onClick={handleCopyPassword}
              >
                Copy Password
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  };

export default GovernmentLoanInfo;
