
import apiService from "../utils/apiService";

export const  getAllInsurance=(params)=>{
        const queryString = new URLSearchParams(params).toString();
        return apiService.get(`/api/v1/user/RM/insurance-loan?${queryString}`);
}

export const insuranceStatusUpdate=(fileNo, paylaod)=>apiService.post(`/api/v1/${fileNo}/update_insurance_status`, paylaod);

export const getInsuranceStatus=()=>apiService.get(`/api/v1/admin/insurance-type`);

export const getInsuranceInfo=(fileNo)=>apiService.get(`/api/v1/user/get_insurance_details/${fileNo}/documents`);
