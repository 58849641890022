import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Card, Typography, Divider, Tooltip, Button, notification } from 'antd';
import { getCreditCardDocInfo } from '../api/creditCardApi';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import pdfIcon from "../assets/pdfIcon.png";

const { Title, Text } = Typography;

const CreditCardInfo = () => {
  const { fileNo } = useParams();
  const location = useLocation();
  const { cardInfo } = location.state || {};
  const [documents, setDocuments] = useState([]);

  // Fetch Credit Card Details
  useEffect(() => {
    const fetchCreditCardDetails = async () => {
      try {
        const { data, status } = await getCreditCardDocInfo(fileNo);
        if (status === 200) {
          setDocuments(data?.data);
        }
      } catch (error) {
        console.error('Error fetching credit card details:', error);
      }
    };
    fetchCreditCardDetails();
  }, [fileNo]);

  if (!cardInfo) {
    return (
      <div className="text-center mt-10 text-red-500 text-lg">
        No credit card information available.
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6">
      <Title level={2} className="mb-6">Credit Card Information</Title>

   {/* Cardholder Details Section */}
<Card className="mb-6 shadow-sm">
  <Title level={4} className="mb-4 text-blue-600">Cardholder Details</Title>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
    <DetailItem label="File Number" value={cardInfo.file_no} />
    <DetailItem label="Partner ID" value={cardInfo.partner_id} />
    <DetailItem label="First Name" value={cardInfo.first_name} />
    <DetailItem label="Last Name" value={cardInfo.last_name} />
    <DetailItem label="Email" value={cardInfo.email} />
    <DetailItem label="Phone" value={cardInfo.phone} />
    <DetailItem label="Gender" value={cardInfo.gender} />
    <DetailItem label="Date of Birth" value={cardInfo.date_of_birth} />
    <DetailItem label="Qualification" value={cardInfo.qualification} />
    <DetailItem label="Permanent Pincode" value={cardInfo.permanent_pincode} />
    <DetailItem label="Current Pincode" value={cardInfo.current_pincode} />
    <DetailItem label="Company Name" value={cardInfo.company_name} />
    <DetailItem label="Designation" value={cardInfo.designation} />
    <DetailItem label="Income" value={`₹${cardInfo.income}`} />
    <DetailItem label="Have Credit Card" value={cardInfo.have_credit_card ? "Yes" : "No"} />
    <DetailItem label="PAN" value={cardInfo.pan} />
    <DetailItem label="Employment Type" value={cardInfo.employment_type} />
    <DetailItem label="Profession" value={cardInfo.profession} />
    <DetailItem label="Office Pincode" value={cardInfo.office_pincode} />
    <DetailItem label="Mother's Name" value={cardInfo.mother_name} />
    <DetailItem label="Status" value={cardInfo.status} />
    <DetailItem label="Created At" value={new Date(cardInfo.created_at).toLocaleString()} />
    <DetailItem label="Updated At" value={new Date(cardInfo.updated_at).toLocaleString()} />
    <DetailItem label="Partner Name" value={cardInfo.partner?.name} />
    <DetailItem label="Partner Email" value={cardInfo.partner?.email} />
  </div>
</Card>


      {/* Document Details Section */}
      <Card className="shadow-sm">
        <Title level={4} className="mb-4 text-blue-600">Document Details</Title>

        {Object.keys(documents).length === 0 || !Object.values(documents).some(value => value && typeof value === 'string' && value.startsWith('http')) ? (
          <p className="text-center text-gray-600 font-semibold text-lg mt-6">
            <span className="inline-block bg-yellow-200 text-yellow-700 px-4 py-2 rounded-md shadow-md">
              No Documents Uploaded Yet
            </span>
          </p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {Object.entries(documents).map(([key, value]) => {
              if (value && typeof value === 'string' && value.startsWith('http')) {
                return (
                  <DocumentItem
                    key={key}
                    label={formatDocumentLabel(key)}
                    url={value}
                    password={documents[`${key}_password`] || 'N/A'}
                  />
                );
              }
              return null;
            })}
          </div>
        )}
      </Card>
    </div>
  );
};

// DetailItem Component
const DetailItem = ({ label, value }) => (
  <div className="flex flex-col">
    <Text className="font-semibold text-gray-700">{label}</Text>
    <Text className="text-gray-900">{value}</Text>
    <Divider className="mt-2 mb-0" />
  </div>
);

// Helper to format document labels
const formatDocumentLabel = (key) => {
  return key
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

const DocumentItem = ({ label, url, password }) => {
  const isImage = url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  const isPDF = url.match(/\.pdf$/) != null;

  const handleDownload = (fileUrl) => {
    window.open(fileUrl, '_blank');
  };

  const handleCopyPassword = () => {
    navigator.clipboard.writeText(password).then(() => {
      notification.success({
        message: 'Password Copied!',
        description: 'The password has been successfully copied to your clipboard.',
        placement: 'topRight',
      });
    }).catch(() => {
      notification.error({
        message: 'Failed to Copy Password',
        description: 'An error occurred while copying the password.',
        placement: 'topRight',
      });
    });
  };

  return (
    <div className="flex flex-col p-4 border rounded-lg shadow-sm bg-white">
      <Text className="font-semibold text-gray-700 text-lg mb-2">{label}</Text>

      <div className="mt-4">
        {isImage ? (
          <div className="flex justify-center mb-4">
            <img
              src={url}
              alt={label}
              className="max-w-xs max-h-40 object-contain rounded-lg cursor-pointer"
              onClick={() => handleDownload(url)}
            />
          </div>
        ) : isPDF ? (
          <div className="flex justify-center mb-4 h-40">
            <img className="w-28 h-28 object-cover" src={pdfIcon} alt="PDF Icon" />
          </div>
        ) : null}

        <div className="flex items-center justify-between space-x-4 mt-4">
          <Button
            icon={<DownloadOutlined />}
            onClick={() => handleDownload(url)}
            className="w-full sm:w-auto bg-green-700 text-white"
          >
            Download
          </Button>

          <Tooltip title={`Password: ${password}`} placement="top">
            <Button
              icon={<EyeOutlined />}
              className="w-full sm:w-auto text-blue-700"
              onClick={handleCopyPassword}
            >
              Copy Password
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default CreditCardInfo;
