import apiService from "../utils/apiService";

export const getAllTaxList = (params) => {
    const queryString = new URLSearchParams(params).toString();
    return apiService.get(`/api/v1/user/RM/taxtation_user_list?${queryString}`);
  };
  export const taxtationStatusUpdate=(fileNo, paylaod)=>apiService.post(`/api/v1/${fileNo}/update_tax_status`, paylaod);

  export const getTaxtationDocInfo=(fileNo)=>apiService.get(`/api/v1/tax/get_tax_details/${fileNo}/documents`);// this url move in Rm api

  export const getTaxStatus = () => apiService.get(`/api/v1/admin/tax-type`);