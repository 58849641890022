
import Loigin from './pages/auth/Login';
import RootLayout from './layouts/RootLayout'; 
import Dashboard from './pages/Dashboard';
import AllLoans from './pages/AllLoans';
import LoanInfo from './pages/LoanInfo';
import AllCreditCard from './pages/AllCreditCard';
import AllGovermentLoan from './pages/AllGovermentLoan';
import Allinsurance from './pages/Allinsurance';
import CreditCardInfo from './pages/CreditCardInfo';
import InsuranceInfo from './pages/InsuranceInfo';
import GovernmentLoanInfo from './pages/GovernmentLoanInfo';
import Taxtaions from './pages/Taxtaions';
import TaxtationInfo from './pages/TaxtationInfo';




 const routes = [


 
  {
    path: '/',
    element: Loigin,
    layout: (props) => <RootLayout {...props} showSidebar={false} showNavbar={false} />,
    protected: false,
    name: 'Relationship Manager Login',
    loginType: 'RelationshipManager',
  },


  {
    path:"/dashboard",
    element: Dashboard,
    layout: RootLayout,
    protected: true,
    name:"RM Dashboard",
    allowedRoles: ['RM'],
  },
  {
    path:"/loan",
    element: AllLoans,
    layout: RootLayout,
    protected: true,
    name:"RM Dashboard",
    allowedRoles: ['RM'],
  },
  {
    path:"/loan/loaninfo/:fileNo",
    element: LoanInfo,
    layout: RootLayout,
    protected: true,
    name:"RM Dashboard",
    allowedRoles: ['RM'],
  },

  {
    path:"/credit-card",
    element: AllCreditCard,
    layout: RootLayout,
    protected: true,
    name:"RM Dashboard",
    allowedRoles: ['RM'],
  },

  {
    path:"/credit-card/card-info/:fileNo",
    element: CreditCardInfo,
    layout: RootLayout,
    protected: true,
    name:"RM Dashboard",
    allowedRoles: ['RM'],
  },
  {
    path:"/goverment-loan",
    element: AllGovermentLoan,
    layout: RootLayout,
    protected: true,
    name:"RM Dashboard",
    allowedRoles: ['RM'],
  },

  {
    path:"/goverment-loan/loan-info/:fileNo",
    element: GovernmentLoanInfo,
    layout: RootLayout,
    protected: true,
    name:"RM Dashboard",
    allowedRoles: ['RM'],
  },

  {
    path:"/insurance-loan",
    element: Allinsurance,
    layout: RootLayout,
    protected: true,
    name:"RM Dashboard",
    allowedRoles: ['RM'],
  },

  {
    path:"/insurance/insurance-info/:fileNo",
    element: InsuranceInfo,
    layout: RootLayout,
    protected: true,
    name:"RM Dashboard",
    allowedRoles: ['RM'],
  },

  {
    path:"/taxtation",
    element: Taxtaions,
    layout: RootLayout,
    protected: true,
    name:"RM Dashboard",
    allowedRoles: ['RM'],
  },

  {
    path:"/taxtation/taxtation-info/:fileNo",
    element: TaxtationInfo,
    layout: RootLayout,
    protected: true,
    name:"RM Dashboard",
    allowedRoles: ['RM'],
  },

];

export default routes;
