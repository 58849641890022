import apiService from "../utils/apiService";

export const  getAllGovermentLoan=(params)=>{
        const queryString = new URLSearchParams(params).toString();
        return apiService.get(`/api/v1/user/RM/government-loan?${queryString}`);
}


export const govermentLoanStatusUpdate=(fileNo, paylaod)=>apiService.post(`/api/v1/${fileNo}/update_government_loan_status`, paylaod);

export const getGovermentLoanStatus=()=>apiService.get(`/api/v1/admin/government-loan-type`);

export const getGovermentLoanInfo=(fileNo)=>apiService.get(`/api/v1/user/get_government_loan_details/${fileNo}/documents`);
