import apiService from "../utils/apiService";

export const  getAllCreditCard=(params)=>{
        const queryString = new URLSearchParams(params).toString();
        return apiService.get(`/api/v1/user/RM/credit-cards?${queryString}`);
}



export const creditCardStatusUpdate=(fileNo, paylaod)=>apiService.post(`/api/v1/${fileNo}/update_credit_card_status`, paylaod);

export const getCreditCardStatus=()=>apiService.get(`/api/v1/admin/credit-card-type `);


export const getCreditCardDocInfo=(fileNo)=>apiService.get(`/api/v1/user/get_credit_card_details/${fileNo}/documents`);// this url move in Rm api
